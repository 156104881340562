import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./style.scss"
import { t } from "../../lib/translate"

const Support = () => {
  return (
    <div className="supportcontainer">
      {/* <div>
        <StaticImage
          className="d-md-block d-none"
          loading="lazy"
          placeholder="blurred"
          src="../../assets/images/page7_bg.png"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
        />
        <StaticImage
          className="d-block d-md-none"
          loading="lazy"
          layout="fullWidth"
          placeholder="blurred"
          src="../../assets/images/yyd_page6_bg.png"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
        />
      </div> */}
      <div className="support">
        <div className="item">
          <h4>{t("body.bai")}</h4>
          <h5>{t("body.supports")}</h5>
          <p>{t("body.coders")}</p>
        </div>
        <div className="item itemDown">
          <h4>{t("body.night")}</h4>
          <h5>{t("body.satisfaction")}</h5>
          <p>{t("body.stores")}</p>
        </div>
      </div>
    </div>
  )
}

export default Support
