import * as React from "react"
import PropTypes from "prop-types"
import { t } from "../../lib/translate"
import "./style.scss"

const Footer = ({ siteTitle }) => (
  <footer
    className="container"
    css={{
      background: `#ffffff`,
    }}
  >
    <div className="footer">
      <div className="text">
        {t("footer.provide")}
        <span className="text email">services@tracking-hub.com</span>
      </div>
      <div className="text mobile-email">services@tracking-hub.com</div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
