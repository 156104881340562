import React from "react"
import { Input as I, Form, Card, Button, Timeline } from "antd"
import { isEmpty, obtain } from "@lsky/tools"
import axios from "axios"
import Text from "../text"
import Select from "../select"
import Container from "../container"
import { t } from "../../lib/translate"

import "./input.scss"
import clsx from "clsx"

const { Search } = I

const getTracksDesp = (data) => {
  if (isEmpty(data)) return data
  return data.map((v) => {
    return obtain(v, "StatusDescription", "")
  })
}

const stringify = (arr) => arr.map((v) => `${v.name}=${v.value}`).join("&")

const parseUrl = () => {
  const [base, urlParam] = window.location.hash.split("?")
  const urls = base.replace("#/", "").split(/\//g)
  if (!urlParam) {
    return [base, null, urls]
  }
  const p = urlParam.split(/&/g).map((str) => {
    const [name, value] = str.split("=")
    return { name, value }
  })
  return [base, p, urls]
}

function appendParamToUrl(name, value) {
  const url = new URL(window.location.href)
  const [base, urlParam] = parseUrl()
  if (!urlParam) {
    url.hash = `${base}?${name}=${value}`
    window.location.href = url.href
    return
  }
  const urlParams = urlParam
    .map((val) => {
      if (val.name === name) {
        val.value = value
      }
      return val
    })
    .filter((v) => v.value)
  url.hash = `${base}?${stringify(urlParams)}`
  window.location.href = url.href
}

function getLanguageFromUrlParams(name) {
  const [base, urlParam] = parseUrl()
  if (!urlParam) {
    return null
  }
  let value = ""
  urlParam.find((val) => {
    if (val.name === name) {
      value = val.value
      return true
    }
    return false
  })
  return value
}

class Input extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      loading: false,
      tracks: [],
      error: false,
      target:
        (typeof window !== "undefined" &&
          window.localStorage.getItem("main-language")) ||
        "en",
      change: false,
      value: undefined, // search value
    }

    this.onSearch = this.onSearch.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
  }

  componentDidMount() {
    const value = getLanguageFromUrlParams("num")
    if (isEmpty(value)) return
    this.setState({ value: value })
    this.onSearch(value)
  }

  onChange(target) {
    const { tracks } = this.state
    if (isEmpty(tracks)) return
    this.setState({ change: true })
    axios
      .post("/language/translate/v2", {
        q: getTracksDesp(tracks),
        target: target,
      })
      .then((res) => {
        const translate = obtain(
          obtain(obtain(res, "data"), "data"),
          "translations"
        )
        if (isEmpty(translate)) {
          throw new Error("服务错误，请联系后台人员~~")
        }

        this.setState({
          target,
          change: false,
          tracks: tracks.map((v, index) => {
            return {
              ...v,
              translatedText: obtain(
                obtain(translate, index),
                "translatedText"
              ),
            }
          }),
        })
      })
      .catch((err) => {
        console.error(err)
        this.setState({
          error: true,
          change: false,
        })
      })
  }

  onInputChange(e) {
    console.log(e.target.value)
    this.setState({ value: e.target.value })
  }

  onSearch() {
    const { value } = this.state
    appendParamToUrl("num", value)
    if (isEmpty(value)) {
      this.setState({ visible: false, loading: false, value: undefined })
      return
    }
    const { target } = this.state
    this.setState({ loading: true, change: true, value })

    let tracks = []
    axios
      .post("/normal/get_tracknum", {
        track_num: value,
      })
      .then((res) => {
        const info = obtain(
          obtain(obtain(obtain(res, "data"), "data"), "origin_info"),
          "trackinfo"
        )
        if (isEmpty(info)) {
          throw new Error("服务错误，请联系后台人员~~")
        }
        tracks = info
        return axios.post("/language/translate/v2", {
          q: getTracksDesp(info),
          target,
        })
      })
      .then((res) => {
        const translate = obtain(
          obtain(obtain(res, "data"), "data"),
          "translations"
        )
        if (isEmpty(translate)) {
          throw new Error("服务错误，请联系后台人员~~")
        }
        tracks = tracks.map((v, index) => {
          return {
            ...v,
            translatedText: obtain(obtain(translate, index), "translatedText"),
          }
        })
        this.setState({
          visible: true,
          loading: false,
          tracks,
          change: false,
          error: false,
        })
      })
      .catch((err) => {
        console.error(err)
        this.setState({
          error: true,
          loading: false,
          tracks: [],
          visible: true,
          change: false,
        })
      })
  }
  


  render() {
    const { visible, loading, tracks, error, target, change, value } =
      this.state

    return (
      <div className="inputcontainer">
        <Form.Item>
        <Search
          value={value}
          className="input"
          placeholder={t("header.placeholder")}
          loading={loading}
          enterButton={t("header.track")}
          size="large"
          onSearch={this.onSearch}
          onChange={this.onInputChange}
        />
        </Form.Item>
        <div
          className={clsx(
            "card-container",
            visible && "visible"
          )}
        >
          <Card
            bordered={false}
            title={
              <div className="card-header">
                <Text
                  className="text"
                  text={
                    error
                      ? t("header.not", "", target)
                      : t("header.publish", "", target)
                  }
                ></Text>
                <div
                  className="card-close"
                  onClick={() => {
                    this.setState({ visible: false, tracks: [], error: false })
                  }}
                >
                  <svg
                    t="1634871862924"
                    className="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1036"
                    width="20"
                    height="20"
                  >
                    <path
                      d="M23.478681 23.478681a82.381335 82.381335 0 0 1 114.510056 0l374.011263 374.011263L886.011263 23.478681a82.381335 82.381335 0 0 1 114.510056 0 82.381335 82.381335 0 0 1 0 114.510056l-374.011263 374.011263 374.011263 374.011263a82.381335 82.381335 0 0 1-114.510056 114.510056l-374.011263-374.011263-374.011263 374.011263a82.381335 82.381335 0 0 1-114.510056 0 82.381335 82.381335 0 0 1 0-114.510056l374.011263-374.011263L23.478681 137.988737a82.381335 82.381335 0 0 1 0-114.510056z"
                      p-id="1037"
                    ></path>
                  </svg>
                </div>
              </div>
            }
          >
            <div className="card-body">
              {!isEmpty(tracks) && (
                <Timeline key="timeline">
                  {tracks.map((option) => {
                    return (
                      <Timeline.Item>
                        <Container className="time-line">
                          <Text
                            className={clsx("intro", "time")}
                            text={option.Date.split(" ").map((v) => {
                              return <Text key={v} text={v}></Text>
                            })}
                          ></Text>
                          <Text
                            className={clsx("intro", "text")}
                            text={option.translatedText}
                          ></Text>
                        </Container>
                      </Timeline.Item>
                    )
                  })}
                </Timeline>
              )}
            </div>
            <div className="card-footer">
              <Select
                className="select"
                language={target}
                onChange={this.onChange}
                loading={change}
              ></Select>
              <Button
                className="button"
                type="text"
                onClick={() => {
                  this.setState({ visible: false, tracks: [], error: false })
                }}
              >
                {t("header.close")}
              </Button>
            </div>
          </Card>
        </div>
      </div>
    )
  }
}

export default Input
