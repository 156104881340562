import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./style.scss"
import { t } from "../../lib/translate"

const CardLay = () => {
  return (
    <div
      id="enterprise"
      css={{
        backgroundColor: "#ffffff",
      }}
    >
      <div className="container">
        <div className="applets">
          <div className="item">
            <div className="leftT">
              <StaticImage
                className="itemImag1"
                loading="lazy"
                placeholder="blurred"
                layout="constrained"
                src="../../assets/images/page6_pic1.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
            <div className="rightT">
              <h4>{t("body.courier")}</h4>
              <p>{t("body.analyse")}</p>
            </div>
          </div>
          <div className="item itemdown">
            <div className="leftT">
              <StaticImage
                className="itemImag2"
                loading="lazy"
                layout="constrained"
                placeholder="blurred"
                src="../../assets/images/page6_pic2.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
            <div className="rightT">
              <h4>{t("body.Logistics")}</h4>
              <p>{t("body.minutes")}</p>
            </div>
          </div>
          <div className="item">
            <div className="leftT">
              <StaticImage
                className="itemImag3"
                loading="lazy"
                layout="constrained"
                placeholder="blurred"
                src="../../assets/images/page6_pic3.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
            <div className="rightT">
              <h4>{t("body.api")}</h4>
              <p>{t("body.connection")}</p>
            </div>
          </div>
          <div className="item itemdown">
            <div className="leftT">
              <StaticImage
                className="itemImag4"
                loading="lazy"
                layout="constrained"
                placeholder="blurred"
                src="../../assets/images/page6_pic4.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>

            <div className="rightT">
              <h4>{t("body.notification")}</h4>
              <p>{t("body.send")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardLay
