import translate from "./translate.json"
import { obtain } from "@lsky/tools"

export const t = (key, defaultValue = "", language = "en") => {
  if (!key) return defaultValue
  let lang = language
  if (typeof window !== "undefined") {
    lang = window.localStorage.getItem("main-language")
  }

  return obtain(
    translate,
    `app.${lang}.${key}`,
    obtain(translate, `app.en.${key}`, defaultValue)
  )
}
