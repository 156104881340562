import React from "react"

import "./index.scss"

export default function Inner({
  className,
  innerClassName,
  useAbsolute,
  children,
  ...attrs
}) {
  if (useAbsolute) {
    return (
      <div
        key="use-absolute"
        className={`inner ${className}`}
        {...attrs}
      >
        <div className={`limit ${className}`}>{children}</div>
      </div>
    )
  }
  return (
    <div key="no-use" className={`limit ${className}`} {...attrs}>
      {children}
    </div>
  )
}
