import React from "react"
import { Select as S } from "antd"
import clsx from "clsx"
import languages from "../../lib/translate/language.json"
import "./index.scss"
const { Option } = S

export default function Select({ className, onChange, language, loading }) {
  return (
    <div>
      <S
        className={clsx("select", className)}
        showSearch
        value={language}
        optionFilterProp="children"
        loading={loading}
        onChange={onChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {languages.languages.map((obj) => {
          return (
            <Option key={obj.language} value={obj.language}>
              {obj.name}
            </Option>
          )
        })}
      </S>
    </div>
  )
}
