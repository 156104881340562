import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Input from "./input"
import "./style.scss"
import { t } from "../../lib/translate"

export default function Search() {
  return (
    <div className="search" id="search">
      <div>
        <StaticImage
          className="d-md-block d-none"
          loading="lazy"
          placeholder="blurred"
          src="../../assets/images/page1_bg.png"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
        />
        <StaticImage
          className="d-block d-md-none"
          loading="lazy"
          layout="fullWidth"
          placeholder="blurred"
          src="../../assets/images/yyd_page1_bg.png"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
        />
      </div>
      <div className="search__content">
        <h1>{t("header.title")}</h1>
        <h2>{t("header.subtitle")}</h2>
        <Input className="search__input"></Input>
      </div>
    </div>
  )
}
