import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./style.scss"
import { t } from "../../lib/translate"

const Web = () => {
  return (
    <div
      css={{
        backgroundColor: "#fff",
      }}
    >
      <div className="container">
        <div className="web">
          <div className="t-first-title">
            <h3>{t("body.intro")}</h3>
            <div className="second-title">{t("body.text")}</div>
          </div>

          <div className="image">
            <StaticImage
              className="d-md-block d-none"
              loading="lazy"
              placeholder="blurred"
              layout="fixed"
              src="../../assets/images/page2__pic.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
            />
            <StaticImage
              className="d-block d-md-none"
              loading="lazy"
              placeholder="blurred"
              src="../../assets/images/yyd_page2_pic.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
            />
          </div>

          <div className="we-title">
            <h3>{t("body.intro1")}</h3>
            <div className="second-title">{t("body.text1")}</div>
          </div>
          <div
            className="row tablecss"
            // css={{
            //   marginLeft: 0,
            //   marginTop: 50,
            // }}
          >
            <div className="col-6 col-md-3 pd0">
              <div className="row">
                <div className="col-md-12 pd0">
                  <div className="d-none d-md-block">
                    <StaticImage
                      className="d-none d-md-block"
                      css={{
                        zIndex: 10,
                        margin: "0 auto",
                      }}
                      loading="lazy"
                      layout="fixed"
                      placeholder="blurred"
                      src="../../assets/images/page3_pic1.png"
                      quality={95}
                      height={80}
                      formats={["AUTO", "WEBP", "AVIF"]}
                    />
                  </div>
                  <div className="d-block d-md-none">
                    <StaticImage
                      className="d-block d-md-none"
                      css={{
                        margin: "0 auto",
                      }}
                      loading="lazy"
                      layout="fixed"
                      placeholder="blurred"
                      src="../../assets/images/page3_pic1.png"
                      quality={95}
                      height={40}
                      formats={["AUTO", "WEBP", "AVIF"]}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="hextext">{t("body.integrate")}</div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 pd0">
              <div className="row">
                <div className="col-md-12 pd0">
                  <div className="d-none d-md-block">
                    <StaticImage
                      className="d-none d-md-block"
                      css={{
                        zIndex: 10,
                        margin: "0 auto",
                      }}
                      loading="lazy"
                      layout="fixed"
                      placeholder="blurred"
                      src="../../assets/images/page3_pic2.png"
                      quality={95}
                      height={80}
                      formats={["AUTO", "WEBP", "AVIF"]}
                    />
                  </div>
                  <div className="d-block d-md-none">
                    <StaticImage
                      className="d-block d-md-none"
                      css={{
                        margin: "0 auto",
                      }}
                      loading="lazy"
                      layout="fixed"
                      placeholder="blurred"
                      src="../../assets/images/page3_pic2.png"
                      quality={95}
                      height={40}
                      formats={["AUTO", "WEBP", "AVIF"]}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="hextext">{t("body.support")}</div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 pd0">
              <div className="row">
                <div className="col-md-12 pd0">
                  <div className="d-none d-md-block">
                    <StaticImage
                      className="d-none d-md-block"
                      css={{
                        zIndex: 10,
                        margin: "0 auto",
                      }}
                      loading="lazy"
                      layout="fixed"
                      placeholder="blurred"
                      src="../../assets/images/page3_speed.png"
                      quality={95}
                      height={80}
                      formats={["AUTO", "WEBP", "AVIF"]}
                    />
                  </div>
                  <div className="d-block d-md-none">
                    <StaticImage
                      className="d-block d-md-none"
                      css={{
                        margin: "0 auto",
                      }}
                      loading="lazy"
                      layout="fixed"
                      placeholder="blurred"
                      src="../../assets/images/page3_speed.png"
                      quality={95}
                      height={40}
                      formats={["AUTO", "WEBP", "AVIF"]}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="hextext hextextdown">
                    {t("body.accelerate")}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 pd0">
              <div className="row">
                <div className="col-md-12 pd0">
                  <div className="d-none d-md-block">
                    <StaticImage
                      className="d-none d-md-block"
                      css={{
                        zIndex: 10,
                        margin: "0 auto",
                      }}
                      loading="lazy"
                      layout="fixed"
                      placeholder="blurred"
                      src="../../assets/images/page3_share.png"
                      quality={95}
                      height={80}
                      formats={["AUTO", "WEBP", "AVIF"]}
                    />
                  </div>
                  <div className="d-block d-md-none">
                    <StaticImage
                      className="d-block d-md-none"
                      css={{
                        margin: "0 auto",
                      }}
                      loading="lazy"
                      layout="fixed"
                      placeholder="blurred"
                      src="../../assets/images/page3_share.png"
                      quality={95}
                      height={40}
                      formats={["AUTO", "WEBP", "AVIF"]}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="hextext hextextdown">{t("body.share")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Web
