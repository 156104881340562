import React from "react"
import Inner from "./inner"

import "./index.scss"

function Container({ className, children }) {
  return <div className={`ccontainer ${className}`}>{children}</div>
}

Container.Inner = Inner

export default Container
