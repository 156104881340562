import React from "react"
import { Link } from "gatsby"
import "./style.scss"
import Select from "../select"
import { t } from "../../lib/translate"
import { StaticImage } from "gatsby-plugin-image"

const Header = props => (
  <header
    id="header"
    style={{
      backgroundColor: `white`,
    }}
  >
    <div className="headerwrap">
      <div className="imageparent">
        <StaticImage
          className="d-none d-md-block imagewrap"
          loading="lazy"
          placeholder="blurred"
          src="../../assets/images/icon.png"
          objectFit="unset"
          quality={95}
          width={58}
          formats={["AUTO", "WEBP", "AVIF"]}
        />
        <StaticImage
          className="d-block d-md-none imagewrap"
          loading="lazy"
          width={29}
          src="../../assets/images/icon.png"
          objectFit="unset"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
        />
      </div>
      <div className="headerdd">
        <Link className="linkdd" to="#bussinessdddd">
          {t("nav.core")}
        </Link>
        <Link className="linkdd" to="#enterprise">
          {t("nav.enterprise")}
        </Link>
        <Select className="selectd" {...props}></Select>
      </div>
    </div>
  </header>
)

export default Header
