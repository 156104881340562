import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import SwiperCore, { Navigation, Pagination, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import "./style.scss"
import { t } from "../../lib/translate"

SwiperCore.use([Navigation, Pagination, A11y])

const Banner = ({}) => {
  return (
    <div className="bussiness2">
      <StaticImage
        className="d-md-block d-none"
        loading="lazy"
        placeholder="blurred"
        src="../../assets/images/page5_pic2.png"
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
      />
      <StaticImage
        className="d-block d-md-none"
        loading="lazy"
        layout="fullWidth"
        placeholder="blurred"
        src="../../assets/images/page5_pic1.png"
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
      />
      <div className="swiped">
        <Swiper
          loop
          pagination={{
            el: ".swiper-pagination",
            bulletClass: "my-bullet", //需设置.my-bullet样式
            bulletActiveClass: "my-bullet-active",
            clickable: true,
          }}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
            clickable: true,
          }}
          slidesPerView={1}
        >
          <SwiperSlide>
            <div className="texts">
              <div className="textdetail">{t("body.starting")}</div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="texts">
              <div className="textdetail">{t("body.along")}</div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="texts">
              <div className="textdetail">{t("body.coverage")}</div>
            </div>
          </SwiperSlide>
          <div className="swiper-pagination"></div>
          {/* <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div> */}
        </Swiper>
      </div>
    </div>
  )
}

export default Banner
