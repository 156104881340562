import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { t } from "../../lib/translate"
import "./style.scss"

const bussiness = () => (
  <div id="bussinessdddd">
    <div className="bussinessd">
      <StaticImage
        className="d-md-block d-none"
        loading="lazy"
        placeholder="blurred"
        src="../../assets/images/page4_bg.png"
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
      />
      <StaticImage
        className="d-block d-md-none"
        loading="lazy"
        placeholder="blurred"
        layout="fullWidth"
        src="../../assets/images/yyd_page4_bg.png"
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
      />
      <div class="textp">
        <h1>{t("body.Our")}</h1>
        <h2>{t("body.basis")}</h2>
      </div>
    </div>
  </div>
)

export default bussiness
