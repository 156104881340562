import React from "react"
import Layout from "../components/layouts/layout"
import Web from "../components/pagetwo/web"
import "./style.scss"
import "antd/dist/antd.css"
import Banner from "../components/banner"
import CardLay from "../components/cardLay/cardLay"
import Support from "../components/support/support"
import Search from "../components/search/search"
import Bussiness from "../components/bussiness/bussiness"
import SEO from "../components/seo"

// export default function Home() {
//   return (
//     <div style={{ color: `purple`, fontSize: `72px` }}>Hello worlddddd!</div>
//   )
// }

export default function Home() {
  const [language, setLanguage] = React.useState(
    (typeof window !== "undefined" &&
      window.localStorage.getItem("main-language")) ||
      "en"
  )

  return (
    <Layout
      language={language}
      onChange={value => {
        if (typeof window !== "undefined") {
          window.localStorage.setItem("main-language", value)
        }
        setLanguage(value)
      }}
    >
      <SEO title="官网" description="官网" />
      <Search />
      <Web />
      <Bussiness />
      <Banner />
      <CardLay />
      <Support />
    </Layout>
  )
}
